import { Flashbar } from "@amzn/awsui-components-react";
import { useState } from "react";


// Static class for managing the flashbar items displayed on the screen. The
// items will be displayed in the most recently created FlashBarDisplay
export class FlashbarPublisher {
    static subscriber;
    static items;

    static pushItem(flashBarItem) {
        FlashbarPublisher.pushItems([flashBarItem]);
    }

    static pushItems(flashBarItems) {
        FlashbarPublisher.setItems([
            ...(this.items || []),
            ...(flashBarItems || [])
        ]);
    }

    static setItems(newItems) {
        FlashbarPublisher.items = newItems || [];
        if (this.subscriber) {
            (this.subscriber)(this.items);
        }
    }

    static clear() {
        FlashbarPublisher.setItems([]);
    }
}

export function FlashbarDisplay() {

    const [localFlashbarItems, setLocalFlashbarItems] = useState([]);

    FlashbarPublisher.subscriber = setLocalFlashbarItems;
    FlashbarPublisher.items = localFlashbarItems;

    return <Flashbar items={localFlashbarItems} />;
}