import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Icon, Spinner } from '@amzn/awsui-components-react';
import { identifyOS, OSType } from "../../helpers/identifyOS";
import { getDownloadLinks } from '../../helpers/getDownloadLinks';
import {User} from "../../core/user";
import {DataAccessService} from "../../core/api";

export const OSDownloadDropDown = (props) => {
    const identifiedOS = identifyOS();
    const unidentifiableOS = identifiedOS === OSType.Unidentifiable;

    const [downloadOptions, setDownloadOptions] = useState([
        {name: OSType.Mac, links: []},
        {name: OSType.Windows, links: []},
        {name: OSType.RedHatLinux, links: []},
        {name: OSType.DebianLinux, links: []}
    ]);
    const [error, setError] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const [linksLoading, setLinksLoading] = useState(true);
    const [displayOptions, setDisplayOptions] = useState(unidentifiableOS);
    const [client] = useState(new DataAccessService({
        baseURL: `${window.location.protocol}//${window.location.host}`,
    }));

    const displayOrHideOptions = () => {
        // Display other options
        if (displayOptions) {
            setDisplayOptions(false);
        } else {
            setDisplayOptions(true);
        }
    }

    // After initial render, load links for buttons
    useEffect(() => {
        const loadLinks = async () => {
            let result;
            try {
                const user = new User();
                result = await getDownloadLinks(client, user.userId, user.email);

                // Populate links to proper display option
                let optionsCopy = downloadOptions;
                optionsCopy[0].links.push(result.macArtifactUrl);
                optionsCopy[1].links.push(result.windowsArtifactUrl);
                optionsCopy[2].links.push(result.rpmArtifactUrl);
                optionsCopy[2].links.push(result.rpmArmArtifactUrl);
                optionsCopy[3].links.push(result.debianArtifactUrl);
                optionsCopy[3].links.push(result.debianArmArtifactUrl);
                setDownloadOptions(optionsCopy);
            } catch(error) {
                setError(true);
                props.errorCallback("There was an error fetching the download links. Please try again.");
            }
            
            setLinksLoading(false);
        }

        loadLinks();
    }, [])

    // Dependencies for buttons being disabled
    useEffect(() => {
        if (linksLoading || error) {
            setButtonsDisabled(true);
        } else {
            setButtonsDisabled(false);
        }
    }, [linksLoading, error])

    // Gather link(s) for auto-identified OS download button
    const isLinux = identifiedOS.search(/LINUX/) !== -1;
    let identifiedLinks = [];
    if (isLinux) {
        identifiedLinks = identifiedOS.search(/Red Hat/) !== -1 ? downloadOptions[2].links : downloadOptions[3].links;
    } else if (identifiedOS == OSType.Mac) {
        identifiedLinks = downloadOptions[0].links;
    } else if (identifiedOS == OSType.Windows) {
        identifiedLinks = downloadOptions[1].links;
    }
    
    return linksLoading ? <Spinner size='large'/>
                : <Box padding={'l'} variant='div' >
                    <div>
                        <Box padding={'l'} variant='div'>
                            <h2>Installing Code Defender</h2>
                            <p>Click the download button to install Code Defender on your device.</p>
                            <div display='inline-block'>
                                <p style={{width: '20%', display: 'inline-block'}}>Recognized device: </p>
                                <p style={{width: '50%', display: 'inline-block'}}>{unidentifiableOS ? <Box ><Icon variant='warning' name='status-warning'/> <b className='identified_os'>{identifiedOS}</b></Box> : <b className='identified_os'>{identifiedOS}</b>}</p>
                            </div>
                            {
                                isLinux ? <span><Button className='identified_download_button' href={identifiedLinks[0]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download x86</Button> - or - <Button className='identified_download_button' href={identifiedLinks[1]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download ARM</Button></span>
                                    : (unidentifiableOS ? <div/>
                                    : <Button className='identified_download_button' href={identifiedLinks[0]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download</Button>)
                            }
                            {
                                unidentifiableOS ? <Box className='unidentifiable_message' variant='p'>Our system wasn't able to detect your device. Please see the list below to view all available installers.</Box> : <div/>
                            }
                        </Box>
                    </div>
                    <Button className='expand_download_options' onClick={displayOrHideOptions} iconName={displayOptions ? 'caret-down-filled' : 'caret-right-filled'} variant='inline-icon'/>
                    Select installer for another device
                    {
                        !displayOptions ? <div/>
                            : downloadOptions.map((value, index) => {
                        
                            return <div key={`download-row-${index}`} style={{paddingTop: '1%'}}>
                                    <Grid gridDefinition={[{colspan: 3}, {colspan: 9}]}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            {value.name}:
                                        </div>
                                        <div style={{float: 'right'}}>
                                            {
                                            value.name.search(/LINUX/) === -1 ? <Button className='download_button' href={value.links[0]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download</Button>
                                                : <Box display='inline'><Button className='download_button' href={value.links[0]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download x86</Button> - or - <Button className='download_button' href={value.links[1]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download ARM</Button></Box>
                                            }
                                        </div>
                                    </Grid>
                                    <hr style={{borderWidth: '1px'}}/>
                                </div>
                        })
                    }
                  </Box>
}