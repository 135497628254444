//React
import { Grid } from '@amzn/awsui-components-react';
import { useState } from 'react';
// Styles
import "../../styles/components/service-footer.scss";
import '../../styles/index.scss';

const ServiceFooter = () => {
  const [year, setYear] = useState(new Date().getFullYear());

    return (
      <footer className="footer">
        <Grid
          disableGutters
          gridDefinition={[
            { colspan: { default: 3, xxs: 12 } },
            { colspan: { default: 7, xxs: 8 } },
            { colspan: { default: 2, xxs: 4 } }]}
        >
          <div className="align-left">
          </div>
          <div id="copyright" className="align-right small-font">
            © 2008 - {year}, Amazon Web Services, Inc. or its affiliates. All rights reserved.
          </div>
          <div className="align-right small-font">
            <a href="http://aws.amazon.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            <a href="http://aws.amazon.com/terms/" target="_blank" rel="noopener noreferrer" >Terms of Use</a>
          </div>
        </Grid>
      </footer>
    ); 
}

export default ServiceFooter;