import { FEATURE_FLAGS } from "../core/user/user";
import { LocalStorage } from "Core/storage";
import { hasAdminLikeRole } from "./hasAdminLikeRole";
import { isAvpFlagOn } from "./isAvpFlagOn";

// Use the user's roles (obtained from the user-details service) to determine
// if delegation admin access should be allowed.
export function isDelegationAccessible() {
    let delegationManagementFlagEnabled = false;
    const avpFlag = isAvpFlagOn();

    const featureFlags = LocalStorage.get(FEATURE_FLAGS);
    if (featureFlags) {
        delegationManagementFlagEnabled = featureFlags['delegation_mgmt'];
    }

    return delegationManagementFlagEnabled && hasAdminLikeRole({ AVP_FEATURE_FLAG_ON: avpFlag });
}