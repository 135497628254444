import { TopNavigation } from '@amzn/awsui-components-react';
import { User } from 'Core/user';
import React, { useState } from 'react';
import iProServe from "../../media/images/proserve.png";

import '../../styles/components/service-header.scss';

const ServiceHeader = (props) => {
    const [user, setUser] = useState(new User());

    return (<TopNavigation
        className='header'
        identity={{
            href: '/',
            logo: {
                src: iProServe,
                alt: 'Service'
            }
        }}
        utilities={[
            {
                type: 'menu-dropdown',
                text: user.full_name,
                description: user.email,
                iconName: 'user-profile',
                items: [
                    { id: 'profile', text: 'Profile', href: `https://phonetool.amazon.com/users/${user.userId}` }
                ]
            }
        ]}
    />)
}

export default ServiceHeader;