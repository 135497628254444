//React
import React, { Component } from "react";
import { DataAccessService } from 'Core/api';

// Components
import ServiceAppLayout from 'Component/ServiceAppLayout.jsx';
import { OSDownloadDropDown } from '../components/OSDownloadDropDown/';

// AWS UI
import {
  Box,
  ColumnLayout,
  Container,
  Flashbar,
  Grid,
  Header,
  SpaceBetween,
  Spinner
} from '@amzn/awsui-components-react';

// Help Functions
import getMetricsForUser from '../helpers/getMetricsForUser';
import installationStatus from '../helpers/installationStatus';
import { personalRisk, totalRisks } from 'Helper/riskMetrics';
import { User } from "Core/user";

// Styles
import css from "Style/pages/service-home-page.scss";

const ValueWithLabel = ({ label, children }) => (
  <SpaceBetween direction="vertical" size="s">
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </SpaceBetween>
)

//Components
class ServiceHomePage extends Component {


  constructor(props) {
    super(props)
    this.client = new DataAccessService();
    this.state = {
      loggedInUser: (new User()).userId,
      flashBarError: false,
      errorMessage: "",
      flashBarSuccess: false,
    }

    this.setFlashBarError = this.setFlashBarError.bind(this);
  }

  setFlashBarError(message) {
    this.setState({ flashBarError: true, errorMessage: message });
  }

  render() {
    return (
      <ServiceAppLayout
        page="ServiceHomePage"
        content={
          <div>
            <Content {...this.props}
              flashBar={this.state.flashBarError || this.state.flashBarSuccess}
              errorCallback={this.setFlashBarError} />
          </div>
        }
        toolsHide={true}
        notifications={[
          this.state.flashBarError ? (
            <div style={{ paddingTop: '68px' }}>
              <Flashbar
                items={[
                  {
                    type: "error",
                    dismissible: true,
                    content: this.state.errorMessage,
                    onDismiss: () => this.setState({ flashBarError: false }),
                  },
                ]}
              />
            </div>
          ) : null,
        ]} />
    );
  }
}

// The content in the main content area of the App layout
class Content extends Component {

  constructor(props) {
    super(props)
    this.metricsSearch = new DataAccessService();
    const user = new User();
    this.state = {
      user: user.userId,
      header: '',
      inputValue: user.email || '',
      data: [],
      modalVisible: false,
      tokenPageIndex: 1,
      riskData: { riskList: [], risksAvailable: false },
    };
    this.setModalVisibility = this.setModalVisibility.bind(this);
  }

  async componentDidMount() {
    this.loadData(this.state.user);
  }

  async loadData(user) {
    let metricsResult;
    this.setState({ loading: true });
    this.setState({ personalInstallStatus: (<Spinner size="large" />) });
    try {
      metricsResult = await getMetricsForUser(this.metricsSearch, {}, user)
    } finally {
      if (metricsResult) {
        if (metricsResult.public) {
          this.setState({
            personalInstallStatus: installationStatus(metricsResult.public.thirty["installs"], metricsResult.public.thirty["missing_installs"],
              metricsResult.public.thirty["issue_with_install"], metricsResult.chain ? metricsResult.chain.length > 0 : false)
          });
          this.setState({ riskData: { riskList: personalRisk(metricsResult.risks, user), risksAvailable: metricsResult.chain ? metricsResult.chain.length > 0 : false } });
        }
        else {
          this.setState({ personalInstallStatus: installationStatus(0, 0, 0, false) });
        }
      } else {
        this.setState({ personalInstallStatus: <div><strong>Could not load your status.</strong><br /> Please try refreshing the page.</div> })
      }
      this.setState({ loading: false });
    }
  }

  setModalVisibility(visibility) {
    this.setState({ modalVisible: visibility })
  }

  render() {
    return (
      <Box padding={{ bottom: "xxl" }}>
        <Box className="custom-home__header">
          <Grid gridDefinition={[{ "colspan": 6, "offset": 2 }]}>
            <div className="custom-home__header-title"
              style={this.props.flashBar ? null : { paddingTop: '68px' }}
            >
              <Box
                color="inherit"
                fontWeight="normal"
                margin={{ bottom: "xs" }}
                padding={{ bottom: "xs" }}
              >
                Professional Services
              </Box>
              <div className="custom-home__header-title">
                <Box fontSize="display-l" padding={{ top: "n", bottom: "s" }} fontWeight="bold" className="awsui-text-large">
                  <div className="custom-home__header-title">
                    <strong>Code Defender</strong>
                  </div>
                </Box>
              </div>
            </div>
          </Grid>
        </Box>
        <div>
          <br />
          <p>{this.state.header}</p>
          <ul>
            {
              this.state.data.map((d, index) => {
                return <li key={index}>{d.Id}<ul><li>{d.Name}</li><li>{d.Date}</li><li>{d.Data}</li></ul></li>
              })
            }
          </ul>
        </div>

        <Grid gridDefinition={[{ "colspan": 6, "offset": 2 }]}>
          <Box padding={{ bottom: "xxl" }}>
            <Box
              margin={{ right: "xxl" }}
              padding={{ right: "xxl" }}
            >
              <SpaceBetween direction="vertical" size="s">

                <div>
                  <Container header={
                    <Header
                      variant="h2"
                      description="Updates once a day"
                    >
                      Your Personal Installation Status
                    </Header>
                  }>
                    <ColumnLayout borders="vertical" columns={2}>
                      <ValueWithLabel label="Installation Status">
                        {this.state.personalInstallStatus}
                      </ValueWithLabel>
                      <ValueWithLabel label="Risk Status">
                        {this.state.loading ? <Spinner size="large" /> : totalRisks(this.state.riskData)}
                      </ValueWithLabel>
                    </ColumnLayout>
                  </Container>
                </div>
                <Container >
                  <div>
                    <ColumnLayout columns={1}>
                      <div data-awsui-column-layout-root={true}>
                        <div style={{ position: "relative" }}>
                          <OSDownloadDropDown errorCallback={this.props.errorCallback} />
                        </div>

                        <div style={{ position: "relative" }}>
                          <p>AWS handles your information as described in the <a href="https://aws.amazon.com/privacy/">AWS Privacy Notice</a>.</p>
                          <p>Copyright AWS and its Affiliates</p>
                          <p>AMAZON CONFIDENTIAL</p>
                        </div>
                      </div>
                    </ColumnLayout>
                  </div>
                </Container>
              </SpaceBetween>
            </Box>
          </Box>
        </Grid>
      </Box>
    )
  }
}

export { Content }
export default ServiceHomePage;