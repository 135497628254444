//React
import React, { useEffect, useState } from "react";
import { DataAccessService } from 'Core/api';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Polaris
import {
    Badge,
    BreadcrumbGroup,
    Button,
    ColumnLayout,
    FormField,
    Header,
    Input,
    Spinner,
    Tabs,
} from '@amzn/awsui-components-react';

import { personalRisk } from 'Helper/riskMetrics';
import riskModal from '../../helpers/riskDetailsModal';
import { formatRiskGraphData } from 'Helper/formatRiskGraphData';
import getMetricsForUser from '../../helpers/getMetricsForUser';
import getDelegationsForUser from '../../helpers/getDelegationsForUser';
import installationStatus from '../../helpers/installationStatus';
import blockedCommitModal from '../../helpers/blockedCommitModal';
import { organizeBlockedCommits, organizeMetrics, organizeUserIssueList } from 'Helper/organizeData';
import { User } from "Core/user";

import { isNewDashboardAccessible } from "../../helpers/isNewDashboardAccessible";


// The content in the main content area of the App layout
export const Dashboard = (props) => {
    const client = new DataAccessService();
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [dataLoad, setDataLoad] = useState(false);
    const [delagateLoad, setDelagateLoad] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [personalItems, setPersonalItems] = useState(props.personalItems);
    const [reportItems, setReportItems] = useState([]);
    const [user, setUser] = useState((new User()).userId);
    const [isUserOnLeave, setIsUserOnLeave] = useState(false);
    const [reportsOnLeaveCount, setReportsOnLeaveCount] = useState(0);
    const [selectedUser, setSelectedUser] = useState(user);
    const [selectedUserNicename, setSelectedUserNicename] = useState(user);
    const [reportOrganizedData, setReportOrganizedData] = useState({});
    const [chain, setChain] = useState([{ text: user, href: '#' }]);
    const [delegations, setDelegations] = useState([]);
    const [modalVisible, setModalVisibility] = useState(false);
    const [modalObject, setModalObject] = useState(null);
    const [userIssueList, setUserIssueList] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [riskPage, setRiskPage] = useState({ index: 1 });
    const [userIssueListTableLen, setUserIssueListTableLen] = useState(10);
    const [riskData, setRiskData] = useState({ riskList: [], risksAvailable: false });
    const [visibleRiskGraphData, setVisibleRiskGraphData] = useState([]);
    const [riskGraphData, setRiskGraphData] = useState([]);
    const [riskGraphType, setRiskGraphType] = useState("personal");
    const [riskCounts, setRiskCounts] = useState({});
    const [searchValue, setSearchValue] = useState('');

    const [personalInstallStatus, setPersonalInstallStatus] = useState();
    const [personalNumCommitItems, setPersonalNumCommitItems] = useState([]);
    const [personalCommitItems, setPersonalCommitItems] = useState([]);

    const getBreadcrumbChainList = (user, selectedUser, chain) => {
        if (selectedUser === "ROOT") {
            return [{ text: user, href: '#' }, { text: selectedUser, href: '#' }];
        }

        const delegationsInChain = chain.filter(chainUser => delegations.includes(chainUser));
        if (user === selectedUser) {
            return [{ text: user, href: '#' }];
        } else if ((chain && chain.includes(user))) {
            const trimmedChainList = chain.slice(chain.indexOf(user));
            trimmedChainList.push(selectedUser);
            return trimmedChainList.map((username) => { return { text: username, href: '#' } })
        } else if (delegationsInChain.length > 0) {
            const trimmedChainList = [user, ...chain.slice(chain.indexOf(delegationsInChain[0]))];
            trimmedChainList.push(selectedUser);
            return trimmedChainList.map((username) => { return { text: username, href: '#' } })
        }
        return [{ text: user, href: '#' }, { text: selectedUser, href: '#' }];
    };

    useEffect(() => {
        setLoading(true);

        loadData(user);
        loadDelegations(user);
    }, []);

    const runSearch = async (e) => {
        e.preventDefault()
        setSelectedUser(searchValue);
        setLoading(true);
        await loadData(searchValue);
        setLoading(false);
    };

    const loadData = async (user) => {
        let result;
        setDataLoad(true);
        setPersonalInstallStatus(<Spinner size="large" />);
        setVisibleRiskGraphData([]);
        setRiskGraphData([]);
        try {
            result = await getMetricsForUser(client, {}, user)
        } finally {
            if (result && result.public) {
                const privateData = result.private;
                const publicData = result.public;
                const aggregatedData = result.aggregated;
                const privateArray = organizeMetrics(privateData, publicData);
                const [blockedCommits, numBlockedCommits] = organizeBlockedCommits(publicData);
                const chain = result.chain ? result.chain : [];
                const userIssueList = organizeUserIssueList(aggregatedData);
                const riskList = personalRisk(result.risks, user);
                const riskCounts = result.activeRiskCount
                const userOnLeave = result.on_leave

                const reportData = result.reports;
                let reportsOnLeave = 0;
                let reportArray = [];
                let reportOrganizedData = {};
                let totalReport = {
                    user: "Total",
                    installations: 0,
                    bypassAttempts: 0,
                    missingInstallations: 0,
                    reports: 0,
                    reportInstallations: 0,
                    reportBypassAttempts: 0,
                    reportMissingInstallations: 0,
                    reportsOnLeave: 0,
                    isUserOnLeave: false,
                    reportPersonalNumBlockedCommits: 0,
                    reportPersonalBlockedCommits: 0,
                    reportNumBlockedCommits: 0,
                    reportBlockedCommits: 0,
                    reportsOnboarded: 0,
                    username: "total"
                }
                if (reportData) {
                    for (let report of reportData) {
                        const [reportPersonalBlockedCommits, reportPersonalNumBlockedCommits] = organizeBlockedCommits(report.public)
                        const [reportBlockedCommits, reportNumBlockedCommits] = organizeBlockedCommits(report.aggregated.public)
                        reportOrganizedData[report.username] = organizeMetrics(report.aggregated.private, report.aggregated.public)
                        reportArray.push(
                            {
                                user: report.nicename,
                                onboarded: report.onboarded,
                                installations: report.public.thirty["installs"],
                                bypassAttempts: report.public.thirty["bypasses"],
                                missingInstallations: report.public.thirty["missing_installs"],
                                reports: report.aggregated.count,
                                reportsOnboarded: report.aggregated.onboarded_count,
                                reportInstallations: report.aggregated.public.thirty["installs"],
                                reportBypassAttempts: report.aggregated.public.thirty["bypasses"],
                                reportMissingInstallations: report.aggregated.public.thirty["missing_installs"],
                                reportsOnLeave: report.aggregated.on_leave_count,
                                reportPersonalNumBlockedCommits: reportPersonalNumBlockedCommits,
                                reportPersonalBlockedCommits: reportPersonalBlockedCommits,
                                reportNumBlockedCommits: reportNumBlockedCommits,
                                reportBlockedCommits: reportBlockedCommits,
                                username: report.username,
                                isUserOnLeave: report.on_leave,
                            }
                        )
                        if (report.on_leave) reportsOnLeave++
                    }

                    const [totalReportBlockedCommits, totalReportNumBlockedCommits] = organizeBlockedCommits(aggregatedData.public)
                    totalReport.reports = aggregatedData.count
                    totalReport.reportsOnLeave = aggregatedData.on_leave_count
                    totalReport.reportsOnboarded = aggregatedData.onboarded_count
                    totalReport.reportInstallations = aggregatedData.public.thirty["installs"]
                    totalReport.reportBypassAttempts = aggregatedData.public.thirty["bypasses"]
                    totalReport.reportMissingInstallations = aggregatedData.public.thirty["missing_installs"]
                    totalReport.reportNumBlockedCommits = totalReportNumBlockedCommits
                    totalReport.reportBlockedCommits = totalReportBlockedCommits
                }
                const riskGraphType = reportArray.length > 0 ? "overall" : "personal"
                const riskGraphData = formatRiskGraphData(riskCounts)
                const selectedGraphData = riskGraphType === "overall" ? riskGraphData.overallData : riskGraphData.personalData

                setPersonalInstallStatus(installationStatus(publicData.thirty["installs"], publicData.thirty["missing_installs"], publicData.thirty["issue_with_install"], chain ? chain.length > 0 : false));
                setPersonalItems(privateArray);
                setPersonalNumCommitItems(numBlockedCommits);
                setPersonalCommitItems(blockedCommits);
                setReportItems(reportArray.length > 0 ? reportArray.sort((a, b) => { return a.user.localeCompare(b.user) }).concat([totalReport]) : reportArray);
                setReportOrganizedData(reportOrganizedData);
                setChain(getBreadcrumbChainList(user, selectedUser, chain));
                setUserIssueList(userIssueList);
                setSelectedUserNicename(result.nicename);
                setRiskData({ riskList: riskList, risksAvailable: chain ? chain.length > 0 : false });
                setRiskCounts(riskCounts);
                setRiskGraphType(riskGraphType);
                setVisibleRiskGraphData(selectedGraphData);
                setRiskGraphData(selectedGraphData);
                setIsUserOnLeave(userOnLeave);
                setReportsOnLeaveCount(reportsOnLeave);
            } else {
                if (result) {
                    setPersonalInstallStatus(installationStatus(0, 0, 0, false));
                }
                else {
                    setPersonalInstallStatus(<div><strong>Could not load your status.</strong><br /> Please try refreshing the page.</div>);
                }
                setPersonalItems([]);
                setPersonalCommitItems([]);
                setReportItems([]);
                setReportOrganizedData([]);
                setChain(getBreadcrumbChainList(user, selectedUser, []));
                setUserIssueList([]);
                setSelectedUserNicename(selectedUser);
                setRiskData({ riskList: [], risksAvailable: false });
                setVisibleRiskGraphData([]);
                setRiskGraphData([]);
            }
            setDataLoad(false);
            if (!dataLoad && !delagateLoad) {
                setLoading(false);
            }
        }

    };

    const loadDelegations = async (user) => {
        let result;
        setDelagateLoad(true);
        try {
            result = await getDelegationsForUser(client, {}, { username: user })
        } finally {
            if (result) {
                setDelegations(result);
            } else {
                setDelegations([]);
            }
            setDelagateLoad(false);
            if (!dataLoad && !delagateLoad) {
                setLoading(false);
            }
        }
    };

    const setModalToBlockedCommit = (data, user) => {
        setModalObject(blockedCommitModal(setModalVisibility, data, user));
    };

    const setModalToRiskData = (riskInfo) => {
        setRiskPageIndex(1, riskInfo);
    };

    useEffect(() => {
        if (riskPage.riskInfo) {
            setModalObject(riskModal(setModalVisibility, riskPage.riskInfo, riskPage.index, setRiskPageIndex));
        }
    }, [riskPage]);

    const setRiskPageIndex = (index, riskInfo) => {
        setRiskPage({ index, riskInfo });
    };

    const hasReportsOrDelegations = () => {
        return (reportItems && reportItems.length > 0)
            || (delegations && delegations.length > 0);
    }

    const getTabs = () => {
        return [{
            id: 'personal',
            label: 'Personal',
            href: '/dashboard'
        }, {
            id: 'reports',
            label: 'Reports',
            href: '/dashboard/reports'
        }, {
            id: 'risks',
            label: [
                "Risk Monitoring",
                <p>&nbsp;</p>,
                riskData.riskList.length > 0
                    ? <Badge color="red">{riskData.riskList.length}</Badge>
                    : <Badge color="grey">0</Badge>
            ],
            href: '/dashboard/risks'
        }].filter(tab => {
            return (tab.id != 'reports') || hasReportsOrDelegations();
        })
    }

    // This is where we redirect if the user doesn't have the new-dashboard 
    // flag
    if (!isNewDashboardAccessible()) {
        navigate("/");
    }

    const tabList = getTabs();

    const onChange = (e) => {
        navigate(e.detail.activeTabHref);
    }

    const activeTabId = tabList
        .filter(tab => tab.href == location.pathname)
        .map(tab => tab.id)
        .shift() || 'personal';

    return (<ColumnLayout>
        <div className=" offset-s-0">
            <BreadcrumbGroup
                id="breadcrumb-group"
                items={[{ text: "Code Defender", href: "/" }].concat(chain)}
                ariaLabel="Breadcrumbs"
                onClick={async (clickDetail) => {
                    if (clickDetail.detail.text !== 'Code Defender') {
                        setSelectedUser(clickDetail.detail.text);
                        setChain(chain.slice(0, chain.indexOf(clickDetail.detail.item) + 1));
                        setLoading(true);
                        await loadData(clickDetail.detail.text);
                        setLoading(false);
                    }
                }}
            />
        </div>

        {hasReportsOrDelegations() &&
            <form style={{ width: "70%" }}>
                <p>Search my reports</p>

                <FormField stretch={true}>
                    <div style={{ display: "flex" }}>
                        <div style={{ flexGrow: 1 }}>
                            <Input
                                id="searchInput"
                                type="search"
                                placeholder="Enter report alias"
                                disableBrowserAutocorrect={true}
                                autocomplete={false}
                                ariaRequired={true}
                                onChange={(({ detail }) => setSearchValue(detail.value))}
                                value={searchValue}
                            />
                        </div>
                        <div style={{ minWidth: '108px' }}>
                            <Button id="searchButton" variant="primary" loading={loading} iconName="search" onClick={runSearch}>Search</Button>
                        </div>
                    </div>

                </FormField>
            </form>
        }

        <Header variant="h1">{selectedUserNicename}</Header>

        {modalVisible && modalObject}

        <Tabs
            activeTabId={activeTabId}
            tabs={tabList}
            disableContentPaddings={true}
            onChange={onChange}
        />

        <Outlet context={{
            chain,
            currentPageIndex,
            delegations,
            loadData,
            loading,
            personalCommitItems,
            personalInstallStatus,
            personalItems,
            reportItems,
            reportsOnLeaveCount,
            riskCounts,
            riskData,
            riskGraphData,
            riskGraphType,
            selectedItems,
            selectedUser,
            setChain,
            setCurrentPageIndex,
            setLoading,
            setModalToBlockedCommit,
            setModalToRiskData,
            setModalVisibility,
            setRiskGraphData,
            setRiskGraphType,
            setSelectedUser,
            setVisibleRiskGraphData,
            userIssueList,
            userIssueListTableLen,
            visibleRiskGraphData
        }} />
    </ColumnLayout >)
}