import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { SideNavigation } from "@amzn/awsui-components-react";
import { isInternalUser } from "../../helpers/isInternalUser";
import { isRulesEngineAccessible } from "../../helpers/isRulesEngineAccessible";
import { isNewDashboardAccessible } from "../../helpers/isNewDashboardAccessible";
import { isDelegationAccessible } from "../../helpers/isDelegationAccessible";
import { isDefenderInstalled } from "../../helpers/isDefenderInstalled";
import { DarkModeToggle } from "../dark-mode-toggle";
import { isRuleManagementAccessible } from "../../helpers/isRuleManagementAccessible";

export const ServiceNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    let dynamicItems = [];
    if (!isDefenderInstalled()) {
        dynamicItems.push({
            type: "link",
            text: "Getting started",
            href: "/installation"
        });
    }
    if (isInternalUser()) {
        let dashboardPath = isNewDashboardAccessible() ? "/dashboard" : "/view_metrics";
        dynamicItems.push({
            type: "link",
            text: "Dashboard",
            href: dashboardPath
        });
    };

    if (isRuleManagementAccessible()) {
        dynamicItems.push({
            type: "section",
            text: "Management tools",
            items: [
                {
                    type: "link",
                    text: "Team rules",
                    href: "/team_rules"
                },
                {
                    type: "link",
                    text: "Repository rules",
                    href: "/repo_rules"
                },
                {
                    type: "link",
                    text: "Repository allow list",
                    href: "/repo_allow_list"
                },
                {
                    type: "link",
                    text: "Resource management permissions",
                    href: "/permissions" //TODO: implementation
                },
                {
                    type: "link",
                    text: "Delegate",
                    href: "/delegation"
                }
            ]
        });
    } else {
        if (isDelegationAccessible()) {
            dynamicItems.push({ type: "link", text: "Delegation", href: "/delegation" });
        }

        if (isRulesEngineAccessible()) {
            dynamicItems.push({ type: "link", text: "Rules Engine", href: "/rules" });
        }
    }

    return (
        <SideNavigation
            id={"SideNavigation"}
            activeHref={location.pathname}
            header={{ href: "/installation", text: "Code Defender" }}
            onFollow={event => {
                if (!event.detail.external) {
                    event.preventDefault();
                    navigate(event.detail.href);
                }
            }}
            items={[
                ...dynamicItems,
                { type: "divider" },
                {
                    type: "section",
                    text: "Helpful resources",
                    items: [
                        {
                            type: "link",
                            text: "FAQ",
                            href: "https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/",
                            external: true
                        },
                        {
                            type: "link",
                            text: "User onboarding",
                            href: "https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserOnboarding/",
                            external: true
                        },
                        {
                            type: "link",
                            text: "About Code Defender",
                            href: "https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/",
                            external: true
                        }
                    ]
                },
                { type: 'divider' },
                {
                    type: "link",
                    text: '',
                    href: '',
                    info: <DarkModeToggle />
                }
            ]}
        />
    );
}
