// React
import React, { Component, useState } from 'react';

// Components
import { ServiceNavigation } from "Component/service-navigation/ServiceNavigation.jsx";
import ServiceBreadcrumbs from 'Component/ServiceBreadcrumbs.jsx';

// Polaris
import { AppLayout } from '@amzn/awsui-components-react';
import { BreadCrumbController } from 'Component/ServiceBreadcrumbs.jsx';
import { FlashbarDisplay } from './FlashbarPublisher';

const DynamicAppLayout = (props) => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  let defaultProps = {}

  if (props.page in BreadCrumbController) {
    defaultProps.breadcrumbs = <ServiceBreadcrumbs {...props} />
  }
  Object.assign(defaultProps, {
    navigation: <ServiceNavigation />,
    contentType: "default",
    navigationOpen: navigationOpen,
    onNavigationChange: ({ detail }) => setNavigationOpen(detail.open),
    notifications: <FlashbarDisplay />,
    stickyNotifications: true,
    toolsHide: true,
    headerSelector: '.header',
    footerSelector: '.footer',
  }, props)

  return (
    <AppLayout {...defaultProps} />
  )
}

// Class ServiceAppLayout is the AppLayout component that is used in the pages.
// Implement like this: <ServiceAppLayout page='GenericNotFound'/>
class ServiceAppLayout extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div
        style={{
          paddingTop: '48px',
          paddingBottom: '30px'
        }}>
        <DynamicAppLayout {...this.props} />
      </div >
    );
  }
}

export default ServiceAppLayout;